import {useEffect} from 'react'
import {batch, useDispatch} from 'react-redux'
import {Switch, Route, Redirect, useLocation} from 'react-router-dom'
import useTypedSelector from '../hooks/use-typed-selector'

import {SystemReinitialising} from '../pages/system-reinitialising/system-reinitialising'
import {
    hasErrorSelector,
    logInStatusSelector,
    userLogInFlowSelector,
} from '../store/state/session-data/selectors'
import {loggedOut, loggingIn} from '../store/state/session-data/action-creators'
import {hasAuthTokenSelector} from '../store/state/auth/selectors'
import {LoginStatus} from '../store/state/session-data/state'
import {LoginPage} from '../pages/login/login-page'

export function UnauthenticatedRoutes(): JSX.Element | null {
    const dispatch = useDispatch()
    const location = useLocation()

    const loginStatus = useTypedSelector(logInStatusSelector)
    const hasError = useTypedSelector(hasErrorSelector)
    const hasAuthToken = useTypedSelector(hasAuthTokenSelector)
    const rebuildingRedux =
        useTypedSelector(userLogInFlowSelector) === false &&
        loginStatus != LoginStatus.LOGGING_OUT &&
        !hasError

    useEffect(() => {
        if (loginStatus === LoginStatus.UNKNOWN) {
            if (hasAuthToken) {
                dispatch(loggingIn())
            } else {
                batch(() => {
                    dispatch(loggedOut())
                })
            }
        }
    }, [dispatch, hasAuthToken, loginStatus])

    if (loginStatus === LoginStatus.UNKNOWN) {
        return null
    }

    if (rebuildingRedux) {
        return <SystemReinitialising />
    }

    return (
        <Switch>
            <Route path="/login" component={LoginPage} />
            <Route
                path="/"
                render={() => <Redirect to={{pathname: '/login', state: {from: location}}} />}
            />
        </Switch>
    )
}
