import GoogleLogin, {GoogleLoginResponse, GoogleLoginResponseOffline} from 'react-google-login'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {authTokenRetrieved} from '../../../../store/state/auth/action-creators'
import {oauthClientIdsSelector} from '../../../../store/state/config/selectors'
import {getCurrentUserEmail} from '../../../../store/state/user/action-creators'
import * as Styled from './styles/primary-button.styled'

export function AdminSignInButton(): JSX.Element | null {
    const dispatch = useDispatch()

    function renderGoogleLoginButton(props?: {onClick(): void}) {
        return (
            <Styled.PrimaryButton id="admin-signin" onClick={props?.onClick}>
                Admin Sign In
            </Styled.PrimaryButton>
        )
    }

    function responseGoogleSuccess(
        response: GoogleLoginResponse | GoogleLoginResponseOffline,
    ): void {
        const authToken: string = (response as GoogleLoginResponse).getAuthResponse().id_token
        const userEmail: string = (response as GoogleLoginResponse).getBasicProfile().getEmail()
        dispatch(getCurrentUserEmail(userEmail))
        dispatch(authTokenRetrieved(authToken))
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function responseGoogleFailure(response: any): void {
        // eslint-disable-next-line no-console
        console.error(response)
        throw new Error(
            `Failure during login: error: ${response.error} (Details: ${response.details})`,
        )
    }

    const oauthClientIds = useTypedSelector(oauthClientIdsSelector)
    const isLoginEnabled = oauthClientIds !== undefined

    return isLoginEnabled && oauthClientIds.google ? (
        <GoogleLogin
            clientId={oauthClientIds.google}
            cookiePolicy={'single_host_origin'}
            onSuccess={responseGoogleSuccess}
            onFailure={responseGoogleFailure}
            render={renderGoogleLoginButton}
        />
    ) : null
}
